/* tslint:disable:max-line-length */
import {Component, computed, EventEmitter, input, Input, OnDestroy, OnInit, Output, signal} from '@angular/core';
import {QuestionDefinition, SubQuestion} from '@shared/models/question-definition';
import moment from 'moment';
import {
  QUESTIONNAIRES_ALLOWED_FOR_BRAID,
  QUESTIONNAIRES_ALLOWED_FOR_BRAID_BUSINESS_UBO
} from '@shared/models/braid/common';
import {Observable, Subscription} from 'rxjs';
import {Profile} from '@shared/models/profile';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {TranslateService} from '@ngx-translate/core';
import {KycProfile} from '@shared/models/kyc-profile';
import {ProfileQuestionsState} from '@app/admin-module/states/profile/profile-questions.state';
import {UploadingFileStatus} from '@app/shared-module/components/questions-answered/questions-answered.component';
import {Account} from "@shared/models/account";

@Component({
  selector: 'shared-answer-view',
  templateUrl: './answer-view.component.html',
  styleUrls: ['./answer-view.component.scss']
})
export class AnswerViewComponent implements OnInit, OnDestroy {

  kycProfile = input.required<KycProfile>();

  @Input()
  block: QuestionDefinition;

  currProfile: Observable<Profile>;

  @Input()
  question: QuestionDefinition | SubQuestion;

  @Output()
  downloadPart = new EventEmitter<string>();

  // This state is only used for admins
  @Input()
  questionsState: ProfileQuestionsState;
  answeredQuestionsSubscription: Subscription;

  uploadingToBraid = signal<UploadingFileStatus>({
    status: false,
    file: '',
    forFiBusiness: false,
    segregateAccountId: null
  });

  questionnaireAllowedForBraid = computed(() => {
    if (!this.block) {
      return false;
    }
    return this.kycProfile().segregateAccounts.find(it => it.braid_business_id) &&
      QUESTIONNAIRES_ALLOWED_FOR_BRAID_BUSINESS_UBO.includes(this.block.question) &&
      this.block.braidUbo.find(it => it.braid_individual_id && it.braid_ubo_id) ||
      (this.kycProfile().segregateAccounts.find(it => it.braid_business_id) || this.kycProfile().segregateAccounts.find(it => it.braid_individual_id)) &&
      QUESTIONNAIRES_ALLOWED_FOR_BRAID.filter(it => !QUESTIONNAIRES_ALLOWED_FOR_BRAID_BUSINESS_UBO.includes(it)).includes(this.block.question);
  });

  constructor(private currentProfileState: CurrentProfileState,
              private alertsService: AlertsService,
              private translateService: TranslateService) {
    this.currProfile = this.currentProfileState.getCurrentProfile();
  }

  ngOnInit() {
    if (this.questionsState) {
      // Stop the loader when new data is received
      this.answeredQuestionsSubscription = this.questionsState.getQuestionsAnswered().subscribe(it => {
        this.uploadingToBraid.set({status: false, file: '', forFiBusiness: false, segregateAccountId: null});
      });
    }
  }

  ngOnDestroy() {
    if (this.answeredQuestionsSubscription) {
      this.answeredQuestionsSubscription.unsubscribe();
    }
  }

  downloadFile(part: string) {
    this.downloadPart.emit(part);
  }

  trimTimestampFromFilename(filename: string): string {
    let result = filename.slice(filename.indexOf('-') + 1);
    if (result.length > 20) {
      result = result.substring(0, 17) + '...';
    }
    return result;
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    return moment(dateString).format('MM/DD/YYYY');
  }

  isQuestionnaireAllowedForBraidFIBusiness(segregateAccount: Account) {
    if (!this.block || !segregateAccount) {
      return false;
    }
    return segregateAccount.braid_fi_business_id &&
      !QUESTIONNAIRES_ALLOWED_FOR_BRAID_BUSINESS_UBO.includes(this.block.question);
  }

  uploadToBraid(segregateAccountId: number, part: string, uploadToFIBusiness: boolean) {
    if (this.uploadingToBraid().status || !this.block) {
      return;
    }

    this.uploadingToBraid.set({
      status: true,
      file: part,
      forFiBusiness: uploadToFIBusiness,
      segregateAccountId: segregateAccountId
    });
    this.questionsState.uploadDocumentToBraid(this.kycProfile().id, this.block.id, segregateAccountId, part, uploadToFIBusiness)
      .subscribe({
        error: (error) => {
          this.alertsService.addAlert({
            type: 'danger',
            message: this.translateService.instant('shared.answer-view: Error while uploading document to Braid'),
          });
          this.uploadingToBraid.set({status: false, file: '', forFiBusiness: false, segregateAccountId: null});
        }
      });
  }

  isUploadedToBraid(fileName: string, segregateAccount: Account, isForFIBusinessRecord: boolean) { // TODO YOU SHOULD CHANGE THIS FUNCTION
    if (!this.block || !this.block.filesUploadedToBraid || this.block.filesUploadedToBraid && !this.block.filesUploadedToBraid.length) {
      return false;
    }
    if (isForFIBusinessRecord) {
      return !!this.block.filesUploadedToBraid.find(it => it.fileName === fileName && it.segregateAccountId === segregateAccount.id && it.braid_fi_business_id === segregateAccount.braid_fi_business_id);
    } else if (!isForFIBusinessRecord && QUESTIONNAIRES_ALLOWED_FOR_BRAID_BUSINESS_UBO.includes(this.block.question)) {
      return !!this.block.filesUploadedToBraid.find(it => it.fileName === fileName && it.segregateAccountId === segregateAccount.id);
    }

    return !!this.block.filesUploadedToBraid.find(it => {
      return it.fileName === fileName &&
        (it.braid_individual_id && it.braid_individual_id === segregateAccount.braid_individual_id ||
          it.braid_business_id && it.braid_business_id === segregateAccount.braid_business_id);
    });
  }

  isUploading(fileName: string, segregateAccountId: number) {
    const uploadingStatus = this.uploadingToBraid();
    return fileName === uploadingStatus.file && segregateAccountId === uploadingStatus.segregateAccountId && uploadingStatus.status;
  }

  shouldDisplay(segregateAccountId: number) {
    return !QUESTIONNAIRES_ALLOWED_FOR_BRAID_BUSINESS_UBO.includes(this.block.question) || (QUESTIONNAIRES_ALLOWED_FOR_BRAID_BUSINESS_UBO.includes(this.block.question) && !!this.block.braidUbo.find(it => it.segregate_account_id === segregateAccountId)?.braid_individual_id);
  }
}
